import { styled } from '@egoist/vue-emotion'
import NerospecOriginLogo from '@/assets/logos/logo_nerospec_dark.svg'
import GHHOriginLogo from '@/assets/logos/logo_ghh_dark.svg'
import GSESOriginLogo from '@/assets/logos/logo_gses_dark.svg'
import SQOriginLogo from '@/assets/logos/logo_sq_dark.svg'
import FusionLogo from '@/assets/logos/fusion_logo.svg'
import FusionLogoDark from '@/assets/logos/fusion_logo_dark.svg'
import FusionLogoMobile from '@/assets/logos/fusion_logo_mobile.svg'
import SMAGLogoMobile from '@/assets/logos/logo_smag_mobile.svg'
import SMAGLogoDesktop from '@/assets/logos/logo_smag.svg'
import EYELogoMobile from '@/assets/logos/logo_eye_mobile.svg'
import EYELogoDesktop from '@/assets/logos/logo_eye.svg'

const GHHLightLogo = styled(GHHOriginLogo)`
  width: 100px;
  path {
    transition: fill 1s linear;
  }
`

const GHHDarkLogo = styled(GHHOriginLogo)`
  width: 100px;
  path {
    fill: white;
    transition: fill 1s linear;
  }
`

const GHHDarkLogoMobile = styled(GHHDarkLogo)`
  width: 45px;
`
const GHHLightLogoMobile = styled(GHHLightLogo)`
  width: 45px;
`

const NerospecLightLogo = styled(NerospecOriginLogo)`
  width: 100px;
  path {
    transition: fill 1s linear;
  }
`

const NerospecDarkLogo = styled(NerospecOriginLogo)`
  width: 100px;
  path {
    fill: white;
    transition: fill 1s linear;
  }
`

const NerospecDarkLogoMobile = styled(NerospecDarkLogo)`
  width: 45px;
`
const NerospecLightLogoMobile = styled(NerospecLightLogo)`
  width: 45px;
`

const GSESLightLogo = styled(GSESOriginLogo)`
  width: 45px;
  path {
    transition: fill 1s linear;
  }
`
const GSESDarkLogo = styled(GSESOriginLogo)`
  width: 45px;
  path {
    fill: white;
    transition: fill 1s linear;
  }
`
const GSESDarkLogoMobile = styled(GSESDarkLogo)`
  width: 45px;
`
const GSESLightLogoMobile = styled(GSESLightLogo)`
  width: 45px;
`

const SQLightLogo = styled(SQOriginLogo)`
  width: 110px;
  path {
    transition: fill 1s linear;
  }
`
const SQDarkLogo = styled(SQOriginLogo)`
  width: 110px;
  path {
    fill: white;
    transition: fill 1s linear;
  }
`
const SQDarkLogoMobile = styled(SQDarkLogo)`
  width: 45px;
`
const SQLightLogoMobile = styled(SQLightLogo)`
  width: 45px;
`

export default [
  {
    organizations: ['GSES'],
    dark: {
      desktop: GSESDarkLogo,
      mobile: GSESDarkLogoMobile,
    },
    light: {
      desktop: GSESLightLogo,
      mobile: GSESLightLogoMobile,
    },
  },
  {
    organizations: ['K+S', 'UMMC', 'GHH'],
    dark: {
      desktop: GHHDarkLogo,
      mobile: GHHDarkLogoMobile,
    },
    light: {
      desktop: GHHLightLogo,
      mobile: GHHLightLogoMobile,
    },
  },
  {
    organizations: ['nerospecSK'],
    dark: {
      desktop: NerospecDarkLogo,
      mobile: NerospecDarkLogoMobile,
    },
    light: {
      desktop: NerospecLightLogo,
      mobile: NerospecLightLogoMobile,
    },
  },
  {
    organizations: ['BAG', 'Pescher'],
    dark: {
      desktop: SQDarkLogo,
      mobile: SQDarkLogoMobile,
    },
    light: {
      desktop: SQLightLogo,
      mobile: SQLightLogoMobile,
    },
  },
  {
    organizations: ['DeutzDays'],
    dark: {
      desktop: FusionLogoDark,
      mobile: FusionLogoMobile,
    },
    light: {
      desktop: FusionLogo,
      mobile: FusionLogoMobile,
    },
  },
  {
    organizations: ['SMAG'],
    dark: {
      desktop: SMAGLogoDesktop,
      mobile: SMAGLogoMobile,
    },
    light: {
      desktop: SMAGLogoDesktop,
      mobile: SMAGLogoMobile,
    },
  },
  {
    organizations: ['EYE', 'MCO'],
    dark: {
      desktop: EYELogoDesktop,
      mobile: EYELogoMobile,
    },
    light: {
      desktop: EYELogoDesktop,
      mobile: EYELogoMobile,
    },
  },
]
