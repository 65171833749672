<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <AssetLoggerManagerStyled v-else>
    <div>
      <h3>{{ title }}</h3>
    </div>
    <div class="type">
      <span class="type-label"> Asset </span>
      <Multiselect
        :loading="$apollo.queries.sourceAssets.loading"
        :options="sourceAssets"
        :value="selectedAsset"
        open-direction="top"
        trackBy="id"
        label="name"
        :placeholder="'Type to search'"
        @select="setSelectedAsset"
        :disabled="mode === 'asset' || !!hasLoggerAssigned"
      />
    </div>
    <div class="type">
      <span class="type-label"> Logger </span>
      <Multiselect
        :loading="$apollo.queries.loggers.loading"
        :options="loggersMapped"
        :value="selectedLogger"
        open-direction="top"
        trackBy="id"
        label="name"
        :placeholder="'Type to search'"
        @select="setSelectedLogger"
        :disabled="mode === 'logger' || !!hasLoggerAssigned"
      />
    </div>
    <InputField :labelWidth="6" class="start">
      <template v-slot:input>
        <input type="datetime-local" v-model.trim="$v.fromTime.$model" placeholder="eg. 2021-06-21, 00:00" class="date-picker" />
      </template>
      <template v-slot:label> start * </template>
    </InputField>
    <InputField :labelWidth="6" class="end">
      <template v-slot:input>
        <input type="datetime-local" v-model="$v.tillTime.$model" placeholder="eg. 2021-06-21, 00:00" class="date-picker" />
      </template>
      <template v-slot:label> end </template>
      <template v-slot:errors v-if="$v.tillTime.$dirty">
        <div class="error" v-if="!$v.tillTime.isGreater">End Date should be higher than Start Date</div>
      </template>
    </InputField>
    <InputField :labelWidth="8" class="height">
      <template v-slot:input>
        <input type="number" v-model.trim="arpHeight" placeholder="height" />
      </template>
      <template v-slot:label> ARP height (cm)</template>
    </InputField>
    <InputField :labelWidth="6" class="comment">
      <template v-slot:input>
        <input type="text" v-model.trim="comment" placeholder="notes" />
      </template>
      <template v-slot:label> Notes </template>
    </InputField>
    <ButtonSolid v-if="!hasLoggerAssigned" @click="createLoggerRelation" :disabled="isDisabled">
      <template v-if="!isConfirming"> Add </template>
      <PlusCircleIcon class="add-icon" />
    </ButtonSolid>
    <ButtonSolid v-if="!!hasLoggerAssigned" @click="updateLoggerRelation" :disabled="isDisabled || !canUpdate">
      <template v-if="!isConfirming"> Update </template>
    </ButtonSolid>
  </AssetLoggerManagerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'
import get from 'lodash/get'
import chroma from 'chroma-js'
import { required, integer } from 'vuelidate/lib/validators'
// import isEqual from 'lodash/isEqual'
import InputField from './InputField'
import { flexCenter } from '@styles/mixins'
import { ButtonSolid } from '@styles/buttons'
import { PlusCircleIcon } from 'vue-feather-icons'
import { FlashMessages } from '@common/singletons'
import { DateTime } from 'luxon'
import ASSETS_QUERY from '#/graphql/operations/assets/sourceAssets.gql'
import LOGGERS_QUERY from '#/graphql/loggers/list.gql'
import CREATE_LOGGER_RELATION_QUERY from '#/graphql/loggers/createLoggerRelation.gql'
import UPDATE_LOGGER_RELATION_QUERY from '#/graphql/loggers/updateLoggerRelation.gql'
import LOGGER_ASSET_QUERY from '#/graphql/loggers/loggerAssetConnection.gql'
import TalpaLoaderWrapper from './TalpaLoaderWrapper'
import isEmpty from 'lodash/isEmpty'

const AssetLoggerManagerStyled = styled('div')`
  display: grid;
  grid-area: logger-asset-manager;
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  button {
    width: 5rem;
  }
  .error {
    color: ${p => p.theme.colors.red};
  }
  .type {
    position: relative;
    max-width: 25rem;
    display: flex;
    align-items: left;
    background: ${p => chroma(p.theme.colors.black).alpha(0.6).css()};
    backdrop-filter: blur(7px);
    padding: 0.25rem;
    .type-label {
      ${flexCenter}
      min-width: 6rem;
      font-size: 16px;
      padding: 0.25rem;
      color: ${p => p.theme.colors.archonBlue};
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
      height: 2.75rem;
    }
    .basic-select {
      max-width: 20rem;
      margin-left: 1rem;
    }
  }
  .date-picker::-webkit-calendar-picker-indicator {
    cursor: pointer;
    margin: 0;
    padding: 0;
    filter: invert(1);
  }
  .add-icon {
    padding-left: 0.2rem;
  }
`

export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
  },
  components: {
    InputField,
    AssetLoggerManagerStyled,
    Multiselect,
    ButtonSolid,
    PlusCircleIcon,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      sourceAssets: [],
      loggers: [],
      loggerRelations: [],
      hasLoggerAssigned: '',
      selectedLogger: '',
      fromTime: '',
      tillTime: null,
      comment: '',
      arpHeight: '',
      selectedAsset: '',
      loggerRelationsLocal: [],
      isConfirming: false,
      selectedTimezone: { id: 'browser', title: 'Browser', value: DateTime.local().zoneName },
    }
  },
  validations: {
    fromTime: {
      required,
    },
    arpHeight: {
      integer,
    },
    tillTime: {
      isGreater() {
        return this.tillTime ? this.fromTime < this.tillTime : true
      },
    },
  },
  computed: {
    canUpdate() {
      return (
        this.fromTime !== this.hasLoggerAssigned?.fromTime ||
        this.tillTime !== this.hasLoggerAssigned?.tillTime ||
        this.comment !== this.hasLoggerAssigned?.comment ||
        this.arpHeight !== this.hasLoggerAssigned?.arpHeightCm
      )
    },
    isDisabled() {
      return this.$v.$invalid
    },
    loggersMapped() {
      // TODO: show only free loggers in dropdown
      return this.loggers.map(logger => {
        return {
          id: logger.id,
          name: logger.serialNumber + logger.model.name,
        }
      })
    },
    loggerRelationWhereInput() {
      const where = {}
      if (this.mode === 'asset') {
        where.machine = {
          id: {
            in: [this.$route.params.id],
          },
        }
      }
      if (this.mode === 'logger') {
        where.loggerWhere = {
          id: {
            in: [this.$route.params.id],
          },
        }
      }
      return where
    },
  },
  methods: {
    setSelectedAsset(model) {
      this.selectedAsset = model
    },
    setSelectedLogger(model) {
      this.selectedLogger = model
    },
    async createLoggerRelation() {
      this.isConfirming = true
      const data = {}
      const selectedFromDate = DateTime.fromISO(this.fromTime, { zone: this.selectedTimezone.value })
      data.assetId = this.selectedAsset.id
      data.loggerId = this.selectedLogger.id
      data.fromTime = selectedFromDate.toUTC().toISO()
      if (this.arpHeight) {
        data.arpHeightCm = parseInt(this.arpHeight)
      }
      if (this.comment) {
        data.comment = this.comment
      }
      if (this.tillTime) {
        const selectedTillDate = DateTime.fromISO(this.tillTime, { zone: this.selectedTimezone.value })
        data.tillTime = selectedTillDate.toUTC().toISO()
      }
      try {
        const response = await this.$apollo.mutate({
          mutation: CREATE_LOGGER_RELATION_QUERY,
          variables: {
            data,
          },
        })
        const id = get(response, 'data.createLoggerRelation.id')
        if (id) {
          FlashMessages.$emit('success', `Logger Asset related created successfully `, {
            timeout: 3000,
          })
          this.$apollo.queries.loggerRelations.refetch()
        }
        this.isConfirming = false
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async updateLoggerRelation() {
      this.isConfirming = true
      const loggerRelation = { ...this.hasLoggerAssigned }
      const data = {
        id: loggerRelation.id,
        loggerId: loggerRelation.logger.id,
        assetId: loggerRelation.machine.id,
        comment: this.comment ? this.comment : loggerRelation.comment,
        arpHeightCm: parseInt(this.arpHeight),
      }
      const fromTime = this.fromTime ? this.fromTime : loggerRelation.fromTime
      const selectedFromTime = DateTime.fromISO(fromTime, { zone: this.selectedTimezone.value })
      data.fromTime = selectedFromTime.toUTC().toISO()
      if (this.tillTime) {
        const selectedTillDate = DateTime.fromISO(this.tillTime, { zone: this.selectedTimezone.value })
        data.tillTime = selectedTillDate.toUTC().toISO()
      }
      try {
        const response = await this.$apollo.mutate({
          mutation: UPDATE_LOGGER_RELATION_QUERY,
          variables: {
            data,
          },
        })
        this.loggerRelationsLocal = get(response, 'data.updateLoggerRelation', null)
        const id = get(response, 'data.updateLoggerRelation.id')
        if (id) {
          FlashMessages.$emit('success', `Logger Asset related updated successfully `, {
            timeout: 3000,
          })
          this.$apollo.queries.loggerRelations.refetch()
        }
        this.isConfirming = false
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    sourceAssets: {
      query: ASSETS_QUERY,
    },
    loggers: {
      query: LOGGERS_QUERY,
    },
    loggerRelations: {
      query: LOGGER_ASSET_QUERY,
      variables() {
        return {
          where: this.loggerRelationWhereInput,
        }
      },
      skip() {
        return isEmpty(this.loggerRelationWhereInput) || this.sourceAssets.length < 1 || this.loggers.length < 1
      },
      result({ data }) {
        this.loggerRelationsLocal = data.loggerRelations
        this.hasLoggerAssigned = data.loggerRelations.find(r => r.tillTime === null)
        this.fromTime = this.hasLoggerAssigned?.fromTime
          ? DateTime.fromISO(this.hasLoggerAssigned.fromTime, { zone: 'utc' }).toLocal().toFormat("yyyy-MM-dd'T'HH:mm")
          : ''
        this.tillTime = this.hasLoggerAssigned?.tillTime
          ? DateTime.fromISO(this.hasLoggerAssigned.tillTime, { zone: 'utc' }).toLocal().toFormat("yyyy-MM-dd'T'HH:mm")
          : ''
        this.comment = this.hasLoggerAssigned ? this.hasLoggerAssigned.comment : ''
        this.arpHeight = this.hasLoggerAssigned ? this.hasLoggerAssigned.arpHeightCm : ''
        if (this.hasLoggerAssigned) {
          const logger = this.hasLoggerAssigned.logger
          const assetAssigned = this.hasLoggerAssigned.machine
          this.selectedLogger = {
            id: logger.id,
            name: logger.serialNumber + logger.model.name,
          }
          this.selectedAsset = this.sourceAssets.find(asset => asset.id === assetAssigned.id)
        } else if (this.mode === 'logger') {
          const logger = this.loggers.find(logger => logger.id === this.$route.params.id)
          this.selectedLogger = {
            id: logger.id,
            name: logger.serialNumber + logger.model.name,
          }
        } else if (this.mode === 'asset') {
          this.selectedAsset = this.sourceAssets.find(asset => asset.id === this.$route.params.id)
        }
      },
    },
  },
}
</script>
